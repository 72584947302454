<template>
  <div class="py-2" :class="{ 'py-4': $route.name === 'message' }">
    <spinner-overlay color="light" :loading="!ticket.id">
      <p class="mt-1">Loading ticket..</p>
    </spinner-overlay>

    <div
      v-if="ticket && ticket.id"
      class="flex flex-wrap justify-center md:space-x-4"
    >
      <card
        :use-padding="false"
        class="relative w-full md:w-1/2"
        :loading="loading"
      >
        <base-button
          class="mt-4 ml-4"
          size="sm"
          color="light"
          :to="{
            name:
              $route.name === 'support-ticket' ? 'support-tickets' : 'messages',
            params: { shouldNotReloadTicketList: !shouldReloadTicketList },
          }"
        >
          Go back to list
        </base-button>
        <div
          class="flex flex-col justify-between space-y-2 p-4 lg:flex-row lg:items-center"
        >
          <div class="flex flex-col space-y-2">
            <p class="text-xl font-black">Ticket #{{ ticket.id }}</p>
            <div class="inline-flex space-x-2">
              <div v-if="ticket.priority">
                <font-awesome-icon
                  :icon="priorityIcon"
                  class=""
                  :class="{
                    'text-green-600': ticket.priority === 3,
                    'text-yellow-600': ticket.priority === 2,
                    'text-red-600': ticket.priority === 1,
                    'animate-pulse': ticket.priority === 1 && !ticket.status,
                  }"
                >
                  {{ ticket.priority }}
                </font-awesome-icon>
              </div>
              <p>
                {{ priorityName }}
              </p>
            </div>
          </div>

          <div class="flex flex-col space-y-2 text-sm lg:text-right">
            <div class="inline-flex items-center space-x-1">
              <font-awesome-icon class="text-gray-600" :icon="typeIcon" />
              <p>{{ typeName }}</p>
            </div>
            <p class="">
              {{ format(new Date(ticket.updated_at), 'yyyy-MM-dd HH:mm') }}
            </p>
          </div>
        </div>

        <SupportTicketForm
          class="px-4"
          :ticket="ticket"
          @loading="loading = $event"
          @updated="setShouldReloadTicketList(true)"
        />

        <div class="py-8 px-4">
          <p class="max-w-md text-lg">
            {{ ticket.message }}
          </p>
        </div>

        <div
          v-if="isAdmin"
          class="flex items-end px-4 pb-2"
          :class="appointmentUrl ? 'justify-between' : 'justify-end'"
        >
          <div v-if="appointmentUrl">
            <p class="text-sm">Appointment:</p>
            <a
              :href="appointmentUrl"
              target="_blank"
              class="max-w-md break-all pr-3 text-sm text-blue-800"
            >
              {{ appointmentUrl }}
              <font-awesome-icon class="text-xs" icon="external-link-alt" />
            </a>
          </div>
          <div class="flex flex-col justify-end text-right">
            <div>
              <a
                v-if="opentokLink"
                :href="opentokLink"
                target="_blank"
                rel="noopener noreferrer"
                class="text-sm text-primary-darker hover:text-primary-darkest"
              >
                <div class="inline-flex items-center justify-between space-x-2">
                  <span>Opentok</span>
                  <font-awesome-icon class="text-xs" icon="external-link-alt" />
                </div>
              </a>
            </div>
            <div>
              <a
                v-if="logrocketLink"
                :href="logrocketLink"
                target="_blank"
                rel="noopener noreferrer"
                class="text-sm text-primary-darker hover:text-primary-darkest"
              >
                <div class="inline-flex items-center justify-between space-x-2">
                  <span>Logrocket</span>
                  <font-awesome-icon class="text-xs" icon="external-link-alt" />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div class="pt-2">
          <desc-list class="border-t bg-gray-50 px-4 text-sm">
            <desc-item>
              <desc-title> Submitted by </desc-title>
              <desc-data>
                <div class="inline-flex flex-col space-y-1">
                  <p class="flex">
                    {{ ticket.user && ticket.user.display_name }}
                    <img
                      v-if="ticket.country"
                      class="ml-2 h-5 w-6"
                      :src="
                        require('@/assets/svg/flags/' +
                          ticket.country.localization.toLowerCase() +
                          '.svg')
                      "
                    />
                  </p>
                  <p>
                    {{
                      ticket.user && ticket.user.email ? ticket.user.email : ''
                    }}
                  </p>
                  <p v-if="phoneNumber">
                    {{ phoneNumber }}
                  </p>
                  <p class="font-mono">
                    {{ ticket.ip }}
                  </p>
                </div>
              </desc-data>
            </desc-item>

            <desc-item>
              <desc-title> Submitted from </desc-title>
              <desc-data>
                <p class="max-w-md break-all">
                  <a :href="ticket.url" target="_blank" class="text-blue-800">{{
                    ticket.url
                  }}</a>
                </p>
              </desc-data>
            </desc-item>

            <desc-item>
              <desc-title> Client </desc-title>
              <desc-data>
                <div class="flex flex-col space-y-2">
                  <p>{{ ticket.browser || '' }}</p>
                  <p>{{ ticket.os || '' }}</p>
                  <p>{{ debugObject.isMobileDevice ? 'Mobile' : 'Desktop' }}</p>
                </div>
              </desc-data>
            </desc-item>

            <desc-item>
              <desc-title> Website permissions </desc-title>
              <desc-data>
                <div class="flex flex-col space-y-2">
                  <p>
                    Webcam permitted:
                    <span class="font-semibold">
                      {{
                        debugObject.isWebsiteHasWebcamPermissions ? 'Yes' : 'No'
                      }}
                    </span>
                  </p>
                  <p>
                    Microphone permitted:
                    <span class="font-semibold">
                      {{
                        debugObject.isWebsiteHasMicrophonePermissions
                          ? 'Yes'
                          : 'No'
                      }}
                    </span>
                  </p>
                  <p>
                    Audio & video streaming supported:
                    <span class="font-semibold">
                      {{ debugObject.isWebRTCSupported ? 'Yes' : 'No' }}
                    </span>
                  </p>
                </div>
              </desc-data>
            </desc-item>
          </desc-list>

          <div v-if="isAdmin" class="p-4">
            <div class="flex flex-col lg:flex-row lg:space-x-4">
              <base-button
                class="focus:outline-none text-gray-700 focus:text-gray-900"
                @click="
                  (showDetails = !showDetails), (showAppointmentInfo = false)
                "
              >
                <span
                  >{{ showDetails ? 'Less' : 'More' }} technical details</span
                >
                <font-awesome-icon
                  :icon="showDetails ? 'caret-up' : 'caret-down'"
                  class="ml-2"
                />
              </base-button>

              <base-button
                v-if="ticket.appointment"
                class="focus:outline-none text-gray-700 focus:text-gray-900"
                @click="
                  (showAppointmentInfo = !showAppointmentInfo),
                    (showDetails = false)
                "
              >
                <span
                  >{{ showAppointmentInfo ? 'Less' : 'More' }} appointment
                  details</span
                >
                <font-awesome-icon
                  :icon="showAppointmentInfo ? 'caret-up' : 'caret-down'"
                  class="ml-2"
                />
              </base-button>
            </div>

            <div v-if="showAppointmentInfo">
              <div class="relative mt-3 overflow-hidden" style="height: 50vh">
                <code
                  class="absolute inset-0 overflow-auto rounded bg-gray-200 px-4 py-2 shadow"
                >
                  <ul>
                    <li
                      v-for="(key, i) in Object.keys(ticket.appointment)"
                      :key="i"
                    >
                      <p class="max-w-xl break-all pt-2 text-sm">
                        {{ key }}:
                        <span class="font-semibold"
                          >{{ ticket.appointment[key] }}
                        </span>
                      </p>
                    </li>
                  </ul>
                </code>
              </div>
            </div>

            <div v-if="showDetails">
              <div class="relative mt-3 overflow-hidden" style="height: 50vh">
                <code
                  class="absolute inset-0 overflow-auto rounded bg-gray-200 px-4 py-2 shadow"
                >
                  <ul>
                    <li v-for="(key, i) in Object.keys(debugObject)" :key="i">
                      <p class="max-w-xl break-all pt-2 text-sm">
                        {{ key }}:
                        <span class="font-semibold"
                          >{{ debugObject[key] }}
                        </span>
                      </p>
                    </li>
                  </ul>
                </code>
              </div>
            </div>
          </div>
        </div>
      </card>
      <div class="w-full md:w-1/3">
        <card class="w-full">
          <p class="pb-2 text-xl font-black">Conversation</p>
          <SupportTicketConversation :ticket="ticket" />
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import SupportTicketConversation from '@/components/support/SupportTicketConversation';
import SupportTicketForm from '@/components/support/SupportTicketForm';

export default {
  components: { SupportTicketConversation, SupportTicketForm },
  data() {
    return {
      format,
      loading: false,
      showDetails: false,
      showAppointmentInfo: false,
      shouldReloadTicketList: false,
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapState('support', ['types', 'statuses', 'priorityList']),
    ...mapGetters('support', { ticket: 'getSelectedTicket' }),
    ...mapGetters('auth', ['isAdmin']),
    phoneNumber() {
      return (
        this.ticket.phone_number || this.ticket.user?.mobile_phone_formatted // eslint-disable-line
      );
    },

    priorityIcon() {
      const icons = {
        1: 'arrow-up',
        2: 'arrow-right',
        3: 'arrow-down',
      };
      return icons[this.ticket.priority];
    },
    priorityName() {
      return this.priorityList.find(prio => prio.id === this.ticket.priority)
        ? this.priorityList.find(prio => prio.id === this.ticket.priority).name
        : 'Not set';
    },
    typeIcon() {
      const icons = {
        0: 'question',
        1: 'chalkboard-teacher',
        2: 'user-md',
        3: 'life-ring',
      };
      return icons[this.ticket.type];
    },
    typeName() {
      return this.types.find(type => type.id === +this.ticket.type)
        ? this.types.find(type => type.id === this.ticket.type).name
        : '-';
    },
    debugObject() {
      if (!this.ticket.debug) {
        return {};
      }
      return JSON.parse(this.ticket.debug);
    },
    logrocketLink() {
      if (!this.ticket) {
        return false;
      }
      if (this.ticket.url && this.ticket.url.toLowerCase().includes('sv.app')) {
        return 'https://app.logrocket.com/7bbsdu/firstvet-vetpanel-se';
      }
      return 'https://app.logrocket.com/7bbsdu/firstvet-vetpanel-eu';
    },
    opentokLink() {
      if (this.ticket?.appointment) {
        const projectId = this.platform === 'intl' ? '46070982' : '45628862';
        return `https://tokbox.com/developer/tools/inspector/account/3083582/project/${projectId}/session/${this.ticket.appointment.session_id}`;
      }
      return false;
    },
    appointmentUrl() {
      if (this.ticket?.appointment) {
        const url =
          this.ticket.url && this.ticket.url.toLowerCase().includes('sv.app')
            ? `${process.env.VUE_APP_API_URL_SV}/sv/vetpanel/appointment/`
            : `${process.env.VUE_APP_BASE_URL}/vetpanel/appointment/`;
        return url + this.ticket.appointment.id;
      }
      return false;
    },
  },
  async mounted() {
    await this.fetchTicket(this.$router.currentRoute.params.id);
    this.updateTicket(this.ticket);
    this.setPreviousTicketId(this.ticket.id);
  },
  methods: {
    ...mapActions('support', ['fetchTicket', 'sendAnswer']),
    ...mapMutations('support', ['hydrateTicketList', 'setPreviousTicketId']),
    updateTicket(ticket) {
      const selectedTicket = ticket;
      selectedTicket.new = false;
      this.hydrateTicketList(selectedTicket);
    },
    setShouldReloadTicketList(shouldReload) {
      this.shouldReloadTicketList = shouldReload;
    },
  },
};
</script>
