var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-2", class: { "py-4": _vm.$route.name === "message" } },
    [
      _c(
        "spinner-overlay",
        { attrs: { color: "light", loading: !_vm.ticket.id } },
        [_c("p", { staticClass: "mt-1" }, [_vm._v("Loading ticket..")])]
      ),
      _vm.ticket && _vm.ticket.id
        ? _c(
            "div",
            { staticClass: "flex flex-wrap justify-center md:space-x-4" },
            [
              _c(
                "card",
                {
                  staticClass: "relative w-full md:w-1/2",
                  attrs: { "use-padding": false, loading: _vm.loading },
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-4 ml-4",
                      attrs: {
                        size: "sm",
                        color: "light",
                        to: {
                          name:
                            _vm.$route.name === "support-ticket"
                              ? "support-tickets"
                              : "messages",
                          params: {
                            shouldNotReloadTicketList:
                              !_vm.shouldReloadTicketList,
                          },
                        },
                      },
                    },
                    [_vm._v(" Go back to list ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col justify-between space-y-2 p-4 lg:flex-row lg:items-center",
                    },
                    [
                      _c("div", { staticClass: "flex flex-col space-y-2" }, [
                        _c("p", { staticClass: "text-xl font-black" }, [
                          _vm._v("Ticket #" + _vm._s(_vm.ticket.id)),
                        ]),
                        _c("div", { staticClass: "inline-flex space-x-2" }, [
                          _vm.ticket.priority
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "font-awesome-icon",
                                    {
                                      class: {
                                        "text-green-600":
                                          _vm.ticket.priority === 3,
                                        "text-yellow-600":
                                          _vm.ticket.priority === 2,
                                        "text-red-600":
                                          _vm.ticket.priority === 1,
                                        "animate-pulse":
                                          _vm.ticket.priority === 1 &&
                                          !_vm.ticket.status,
                                      },
                                      attrs: { icon: _vm.priorityIcon },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.ticket.priority) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("p", [
                            _vm._v(" " + _vm._s(_vm.priorityName) + " "),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col space-y-2 text-sm lg:text-right",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "inline-flex items-center space-x-1",
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "text-gray-600",
                                attrs: { icon: _vm.typeIcon },
                              }),
                              _c("p", [_vm._v(_vm._s(_vm.typeName))]),
                            ],
                            1
                          ),
                          _c("p", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.format(
                                    new Date(_vm.ticket.updated_at),
                                    "yyyy-MM-dd HH:mm"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("SupportTicketForm", {
                    staticClass: "px-4",
                    attrs: { ticket: _vm.ticket },
                    on: {
                      loading: function ($event) {
                        _vm.loading = $event
                      },
                      updated: function ($event) {
                        return _vm.setShouldReloadTicketList(true)
                      },
                    },
                  }),
                  _c("div", { staticClass: "py-8 px-4" }, [
                    _c("p", { staticClass: "max-w-md text-lg" }, [
                      _vm._v(" " + _vm._s(_vm.ticket.message) + " "),
                    ]),
                  ]),
                  _vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticClass: "flex items-end px-4 pb-2",
                          class: _vm.appointmentUrl
                            ? "justify-between"
                            : "justify-end",
                        },
                        [
                          _vm.appointmentUrl
                            ? _c("div", [
                                _c("p", { staticClass: "text-sm" }, [
                                  _vm._v("Appointment:"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "max-w-md break-all pr-3 text-sm text-blue-800",
                                    attrs: {
                                      href: _vm.appointmentUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.appointmentUrl) + " "
                                    ),
                                    _c("font-awesome-icon", {
                                      staticClass: "text-xs",
                                      attrs: { icon: "external-link-alt" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-col justify-end text-right",
                            },
                            [
                              _c("div", [
                                _vm.opentokLink
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-sm text-primary-darker hover:text-primary-darkest",
                                        attrs: {
                                          href: _vm.opentokLink,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "inline-flex items-center justify-between space-x-2",
                                          },
                                          [
                                            _c("span", [_vm._v("Opentok")]),
                                            _c("font-awesome-icon", {
                                              staticClass: "text-xs",
                                              attrs: {
                                                icon: "external-link-alt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", [
                                _vm.logrocketLink
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-sm text-primary-darker hover:text-primary-darkest",
                                        attrs: {
                                          href: _vm.logrocketLink,
                                          target: "_blank",
                                          rel: "noopener noreferrer",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "inline-flex items-center justify-between space-x-2",
                                          },
                                          [
                                            _c("span", [_vm._v("Logrocket")]),
                                            _c("font-awesome-icon", {
                                              staticClass: "text-xs",
                                              attrs: {
                                                icon: "external-link-alt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "desc-list",
                        { staticClass: "border-t bg-gray-50 px-4 text-sm" },
                        [
                          _c(
                            "desc-item",
                            [
                              _c("desc-title", [_vm._v(" Submitted by ")]),
                              _c("desc-data", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "inline-flex flex-col space-y-1",
                                  },
                                  [
                                    _c("p", { staticClass: "flex" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ticket.user &&
                                              _vm.ticket.user.display_name
                                          ) +
                                          " "
                                      ),
                                      _vm.ticket.country
                                        ? _c("img", {
                                            staticClass: "ml-2 h-5 w-6",
                                            attrs: {
                                              src: require("@/assets/svg/flags/" +
                                                _vm.ticket.country.localization.toLowerCase() +
                                                ".svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ticket.user &&
                                              _vm.ticket.user.email
                                              ? _vm.ticket.user.email
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm.phoneNumber
                                      ? _c("p", [
                                          _vm._v(
                                            " " + _vm._s(_vm.phoneNumber) + " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("p", { staticClass: "font-mono" }, [
                                      _vm._v(" " + _vm._s(_vm.ticket.ip) + " "),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "desc-item",
                            [
                              _c("desc-title", [_vm._v(" Submitted from ")]),
                              _c("desc-data", [
                                _c("p", { staticClass: "max-w-md break-all" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-blue-800",
                                      attrs: {
                                        href: _vm.ticket.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.ticket.url))]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "desc-item",
                            [
                              _c("desc-title", [_vm._v(" Client ")]),
                              _c("desc-data", [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col space-y-2" },
                                  [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.ticket.browser || "")),
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.ticket.os || "")),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.debugObject.isMobileDevice
                                            ? "Mobile"
                                            : "Desktop"
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "desc-item",
                            [
                              _c("desc-title", [
                                _vm._v(" Website permissions "),
                              ]),
                              _c("desc-data", [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col space-y-2" },
                                  [
                                    _c("p", [
                                      _vm._v(" Webcam permitted: "),
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.debugObject
                                                  .isWebsiteHasWebcamPermissions
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(" Microphone permitted: "),
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.debugObject
                                                  .isWebsiteHasMicrophonePermissions
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        " Audio & video streaming supported: "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.debugObject
                                                  .isWebRTCSupported
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isAdmin
                        ? _c("div", { staticClass: "p-4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-col lg:flex-row lg:space-x-4",
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "focus:outline-none text-gray-700 focus:text-gray-900",
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.showDetails = !_vm.showDetails),
                                          (_vm.showAppointmentInfo = false)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showDetails ? "Less" : "More"
                                        ) + " technical details"
                                      ),
                                    ]),
                                    _c("font-awesome-icon", {
                                      staticClass: "ml-2",
                                      attrs: {
                                        icon: _vm.showDetails
                                          ? "caret-up"
                                          : "caret-down",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.ticket.appointment
                                  ? _c(
                                      "base-button",
                                      {
                                        staticClass:
                                          "focus:outline-none text-gray-700 focus:text-gray-900",
                                        on: {
                                          click: function ($event) {
                                            ;(_vm.showAppointmentInfo =
                                              !_vm.showAppointmentInfo),
                                              (_vm.showDetails = false)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showAppointmentInfo
                                                ? "Less"
                                                : "More"
                                            ) + " appointment details"
                                          ),
                                        ]),
                                        _c("font-awesome-icon", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            icon: _vm.showAppointmentInfo
                                              ? "caret-up"
                                              : "caret-down",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.showAppointmentInfo
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "relative mt-3 overflow-hidden",
                                      staticStyle: { height: "50vh" },
                                    },
                                    [
                                      _c(
                                        "code",
                                        {
                                          staticClass:
                                            "absolute inset-0 overflow-auto rounded bg-gray-200 px-4 py-2 shadow",
                                        },
                                        [
                                          _c(
                                            "ul",
                                            _vm._l(
                                              Object.keys(
                                                _vm.ticket.appointment
                                              ),
                                              function (key, i) {
                                                return _c("li", { key: i }, [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "max-w-xl break-all pt-2 text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(key) + ": "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-semibold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.ticket
                                                                .appointment[
                                                                key
                                                              ]
                                                            ) + " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.showDetails
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "relative mt-3 overflow-hidden",
                                      staticStyle: { height: "50vh" },
                                    },
                                    [
                                      _c(
                                        "code",
                                        {
                                          staticClass:
                                            "absolute inset-0 overflow-auto rounded bg-gray-200 px-4 py-2 shadow",
                                        },
                                        [
                                          _c(
                                            "ul",
                                            _vm._l(
                                              Object.keys(_vm.debugObject),
                                              function (key, i) {
                                                return _c("li", { key: i }, [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "max-w-xl break-all pt-2 text-sm",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(key) + ": "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-semibold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.debugObject[
                                                                key
                                                              ]
                                                            ) + " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-full md:w-1/3" },
                [
                  _c(
                    "card",
                    { staticClass: "w-full" },
                    [
                      _c("p", { staticClass: "pb-2 text-xl font-black" }, [
                        _vm._v("Conversation"),
                      ]),
                      _c("SupportTicketConversation", {
                        attrs: { ticket: _vm.ticket },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }